import React, {useEffect, useState} from 'react';
import Grid from '@mui/material/Grid';

import {useLoaderData, useNavigation, useLocation} from "react-router-dom";

import {isMobile} from "react-device-detect";

import {hasFullAccess, isLibrary} from "ultra/helpers/auth";
import {DEFAULT_CITY} from 'ultra/const/general';
import {getClientCity, getCityConfig} from 'ultra/configs/general';

import {useGlobalCustomContext} from '../../../../Helpers/context';
import {attachNodeImage} from '../../Helpers/user'; // attachUsersForFields

import {useConfigStore} from '../../../../Stores/config';

import PreloaderTable from '../../../../Components/PreloaderTable';

import TableView from '../../../Content/Widgets/Table';
import BreadcrumbsWidget from '../../../Content/Widgets/Breadcrumbs';
import TitleWidget from '../../../Content/Widgets/Title';
import NodeInlineActions from '../../../Content/Widgets/NodeInlineActions';

// import {useBackdropPreloaderStore} from '../../../../Stores/backdropPreloader';

import {NODE_ACTIONS} from '../../../../Modules/Content/Reducers/node';
import FiltersLine from '../../../Content/Widgets/FiltersLine';
import {getLibrary} from '../../Helpers/library';

import UserNodesList from '../../Components/UserNodesList'

import {setPageTitle} from '../../../../Helpers/router';

import './index.scss';

export default () => {
    const rawNodes = useLoaderData();
    const navigation = useNavigation();
    const {configs} = useConfigStore();
    const location = useLocation();

    // const userFields = ['_created_by', '_reading_by'];

    const {nodeState, nodeDispatch} = useGlobalCustomContext();
    // const {hideBackdropPreloader} = useBackdropPreloaderStore()
    const {userState} = useGlobalCustomContext();

    const [city] = useState(getClientCity(window) || DEFAULT_CITY);
    const [fullAccess, setFullAccess] = useState();
    const [tableOptions, setTableOptions] = useState();
    const [fields, setFields] = useState();
    const [nodes, setNodes] = useState();
    const [config, setConfig] = useState();
    const [inlineFilter, setInlineFilter] = useState();

    useEffect(() => {
        const bookFields = configs.content.nodes['book'].fields;
        bookFields._created_by.placeholder = "Власник"

        const fields = attachNodeImage(bookFields);

        fields.poster.placeholder = "Обгортка"

        if (isMobile) {
            fields.title.type = "book-title-with-status"
        } else {
            fields.status = {
                placeholder: "Статус",
                type: 'book-status'
            }
        }

        setFields(fields);

        // updateNodes(rawNodes);

        // setPageTitle(fullAccess === true ? 'Бібліотека' : fullAccess === false ? 'Моя Бібліотека' : '');
        setPageTitle('Бібліотека');
    }, [])

    useEffect(() => {
        if (userState?.user) {
            const fullAccess = hasFullAccess(userState?.user) || isLibrary(userState?.user);

            const filterValues = {
                // reading: "Читаю"
                // given: "Передано почитати"
                // return: "Повертаю"

                // Заброньовані
                // overdue: "Перевищено строки повернення"
                // deposit: "Внести депозит"
                // take: "Віддати депозит"
            }

            let filterOrder = [
                // 'deposit',
                // 'reading',
                // 'return',
                // 'take',
                // 'given',
                // 'overdue',
            ]

            if (!isLibrary(userState?.user)) {
                filterOrder.push('my')
                filterOrder.push('notmine')

                if (userState?.user?.library) {
                    filterValues.my = `Мої книги (${userState?.user?.library?.[city]?.owner})`;
                    filterValues.notmine = `Читаю (${userState?.user?.library?.[city]?.reading})`;
                } else {
                    filterValues.my = `Мої книги`;
                    filterValues.notmine = `Читаю`;
                }
            }

            if (fullAccess) {
                filterValues['all_given'] = 'Всі активні книги';
                filterValues['all_overdue'] = 'Всі книги з перевищеними строками повернення';
                filterValues['all_deposit'] = 'Всі книги що чекають внесок депозиту';
                filterValues['all_return'] = 'Всі книги що чекають повернення депозиту'; //'Всі книги на стадії повернення';

                filterOrder = filterOrder
                    .concat([
                        'all_given',
                        'all_deposit',
                        'all_return',
                        'all_overdue',
                    ])
            }

            const complex = {
                id: 'books',
                field: {
                    default: fullAccess ? '' : userState?.user?.library?.owner ? 'notmine' : 'my',
                    type: "complex",
                    placeholder: fullAccess ? "Фільтр" : "Мої книги",
                    options: {
                        hasNoEmptyValue: !fullAccess,
                        display: !fullAccess ? 'chips' : '', // && complex.field.options
                    },
                    values: {
                        values: filterValues,
                        order: filterOrder
                    }
                }
            }

            const config = {
                showFilters: {
                    filterBy: []
                }
            }

            // if (fullAccess || userState?.user?.library) {
            //     config.showFilters.filterBy.push({
            //         id: 'city',
            //         field: {
            //             type: "city",
            //             list: Object.keys(userState.user.library)
            //         }
            //     })
            // }

            if (fullAccess) {
                config.showFilters.filterBy.push(complex)
                config.showFilters.filterBy.push({
                    id: 'region',
                    field: {
                        placeholder: 'Район',
                        source: 'regions',
                        type: 'filter'
                    }
                })

                setConfig(config)
            }
            else {
                setInlineFilter([complex]);
            }


            let tableOptions;
            if (isMobile) {
                tableOptions = {
                    visible: ['_node_image', 'title'],
                    hidden: ['notes', 'region', 'deposit', 'maxTerm', 'author']
                }
            } else {
                tableOptions = {
                    visible: ['_node_image', 'title', 'status', 'region'],
                    hidden: ['notes', 'deposit', 'maxTerm', 'author']
                }
            }

            if (fullAccess) {
                tableOptions.hidden = [
                    '_reading_by',
                    ...tableOptions.hidden,
                    '_created_by',
                ]
            }

            tableOptions.hidden.push('_did')

            tableOptions.noHeaders = true

            setTableOptions(tableOptions)
            setFullAccess(fullAccess);
        }
    }, [userState?.user])

    useEffect(() => {
        if (nodeState.updatePageContentStart) {
            nodeDispatch({type: NODE_ACTIONS.UPDATE_PAGE_CONTENT_END});

            updateNodes({});
            getLibrary(city, location.search)
                .promise
                .then(updateNodes)
        }
    }, [nodeState])

    useEffect(() => {
        if (navigation.state === 'idle') {
            updateNodes(rawNodes)
        }
    }, [navigation])

    function updateNodes(n) {
        setNodes(n)
        // setNodes({...nodes, list: undefined})
        // attachUsersForFields(nodes, userFields)
        //     .then(() => {
        //         setNodes(nodes);
        //         hideBackdropPreloader();
        //     })
    }

    const selectClasses = (data) => {}

    const content = {
        title: fullAccess === true ? 'Бібліотека' : fullAccess === false ? 'Моя Бібліотека' : '',
    }

    const permits = {
        children: {
            read: ['book']
        }
    }

    const breadcrumbs = {
        '\\': {
            title: getCityConfig(configs, city).city || 'Головна'
        }
    }

    return <div id="Content" className='Content'>
            <div className='AdminLibraryPage'>
                <TitleWidget permits={permits} content={content} config={config} breadcrumbs={breadcrumbs} />
                {content?.title && <BreadcrumbsWidget links={breadcrumbs} />}

                {!fields && <PreloaderTable/>}

                {(fullAccess === false) && <div className='MobileFiltersLine'>
                    <FiltersLine
                        size="small"
                        filters={inlineFilter}
                    />
                </div>}

                {fields && nodes && <TableView
                    showPreloader={!nodes?.list}
                    content={nodes}
                    fields={fields}
                    showPagination={true}
                    tableOptions={tableOptions}
                    actions={(id) => NodeInlineActions({
                        permits: nodes.permits[id],
                        node: nodes.list[id],
                        unfolded: isMobile ? true : false,
                        city,
                        hasFullAccess: hasFullAccess(userState.user)
                    })}
                    selectClasses={selectClasses}
                />}
            </div>

            {userState?.user && <Grid container spacing={1}>
                <Grid item xs={12}>
                    <div className='labelWrap labelWrapRow'>
                        <span className='label'>Статистика:</span>
                        <span className='labelValue'>
                            <UserNodesList list={userState?.user?.nodes?.all} type="book"/>
                        </span>
                    </div>
                </Grid>
            </Grid>}

        </div>
}
