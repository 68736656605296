import {useEffect, useState} from "react";

import Grid from '@mui/material/Grid';

import {DEFAULT_CITY} from 'ultra/const/general';
import {getClientCityTimezone} from 'ultra/configs/general';
import {getClientCity} from 'ultra/configs/general';
import {isLibrary, isAdmin} from 'ultra/helpers/auth';
import {getFormatedDate, timestampToDate} from 'ultra/helpers/date'

import {useConfigStore} from '../../../../../../../../../Stores/config';
import {isYoursAccount} from '../../../../../../../../../Helpers/user';
import {useGlobalCustomContext} from '../../../../../../../../../Helpers/context';
import {getBookStatuscode} from '../..//../../../../../Helpers/library'

import ReaderDetails from '../ReaderDetails';

export default function BookIsReading(props) {
    const {book} = props;
    const {userState} = useGlobalCustomContext();

    const {configs} = useConfigStore();

    const [timeZone, setTimeZone] = useState()
    useEffect(() => {
        const city = getClientCity(window) || DEFAULT_CITY;
        setTimeZone(getClientCityTimezone(configs, city));
    }, [configs])

    const [hasFullAccess] = useState(isAdmin(userState?.user) || isLibrary(userState?.user));
    const [isOwner, setIsOwner] = useState();

    useEffect(() => {
        setIsOwner(isYoursAccount(userState, book?._created_by))
    }, [userState?.user?.id, book])

    return <>
        <div className="text">
            <p>
                <span>Книга зайнята до </span>
                {getFormatedDate(timestampToDate(book?._reading_till), timeZone)}
            </p>
            {getBookStatuscode(book) === 'overdue' && <p>Прострочено термін повернення</p>}
        </div>
        
        {(hasFullAccess || isOwner) && <Grid container spacing={2}>
            <Grid item xs={12}>
                <ReaderDetails book={book} fullWidth />
            </Grid>
        </Grid>}
    </>
}
