import BugReportIcon from '@mui/icons-material/BugReport';

import BreadcrumbsWidget from '../../../../../Modules/Content/Widgets/Breadcrumbs';

export default function ErrorPageContent(props) {
    const {error} = props;

    return <div className='ErrorPage'>

        {error.status && <div className="status"><BugReportIcon className="icon" /> {error.status}</div>}
        <div className="message">{error.statusText || error.message}</div>

        {error.status === 401 && !(error.statusText && error.message) &&
            <div className="message">
                Сторінка доступна лише авторизованим користувачам
            </div>
        }

        {(error.status === 401 || error.status === 403) && <p className='actionsBox'><a className='pseudoButton' href="/profile/login">Авторизуватись</a></p>}

        {error.breadcrumbs && <BreadcrumbsWidget links={error.breadcrumbs} hide={error.hideBreadcrumbs} />}
        {/* Додати пошук тут */}
    </div>
}
