// import {useState} from "react";
// import {getClientCity} from 'ultra/configs/general';
// import {DEFAULT_CITY} from 'ultra/const/general';

import PriceWidget from '../../../../../../../../Widgets/Price';

import MessageToOwner from '../../MessageToOwner';

export default function DepositReturnStep(props) {
    const {submit, content, belongsToLibrary} = props;

    // const [city] = useState(getClientCity(window) || DEFAULT_CITY)

    return <>
        <p className="titleEmojiText titleEmojiTitle">Заявка на повернення відправлена</p>
        <p className="titleEmoji">💬</p>
        {belongsToLibrary && <p className="titleEmojiText">
            Очікуйте на повідомлення від бібліотекаря
        </p>}

        {/* // TODO: remove after Prostir release */}
        {belongsToLibrary && <div className="contact">
            <MessageToOwner belongsToLibrary={belongsToLibrary} book={content} onClick={submit}/>
        </div>}
        {/* // TODO: return this code */}
        {/* {!belongsToLibrary && <p className="titleEmojiText">
            <span>Для {content?.deposit?.price && <>повернення завдатку <PriceWidget value={content.deposit} /> та </>} </span>
            <span>отримання книги - зв'яжіться з власником </span>
        </p>} */}

        {/* <div className="contact">
            <MessageToOwner belongsToLibrary={belongsToLibrary} book={content} onClick={submit}/>
        </div> */}
    </>
}
