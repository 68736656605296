import {isUndefined } from "lodash";

import {getTelegramUserId} from './telegram';

export function getUserID(userState) {
  return userState?.user?.id || getTelegramUserId();
}

export function isYoursAccount(userState, id) {
    if (isUndefined(id) || isUndefined(getUserID(userState))) return false

    return getUserID(userState) === id;
}

export function userHasAllNodes(nodes) {
    let result = 0;

    Object.keys(nodes.all).map(city => {
        result += Object.keys(nodes.all[city]).length
    })
  
    return result !== 0;
}

export function userHasNodesForCity(userNodes, city) {
    let result = 0;
  
    if (userNodes.all && userNodes?.all?.[city]) {
      Object.keys(userNodes.all[city]).map(type => {
        result += userNodes.all[city][type]
      })
    }
  
    if (userNodes.singleton && userNodes?.singleton?.[city]) {
      Object.keys(userNodes.singleton[city]).map(type => {
        result += userNodes.singleton[city][type]
      })
    }
  
    return result > 0;
}

export function userHasBooks(library, city) {
    let result = 0;
  
    if (library[city]?.owner) result += library[city].owner
  
    if (library[city]?.reading) result += library[city].reading

    return result > 0;
}
  
export function userHasNetworking(nodes, city) {
    return nodes?.singleton?.[city]?.['networking'];
}
