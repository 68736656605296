import {useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';

import {useNavigationStore} from '../../../../Stores/navigation';

import PreloaderForm from '../../../../Components/PreloaderForm';

import VerifyEmail from './VerifyEmail';
import UpdatePassword from './UpdatePassword';

import './index.scss';

export default function Update() {
    const {contentLoading} = useNavigationStore()

    const [oobCode, setOOBCode] = useState();
    const [mode, setMode] = useState();
    const [continueUrl, setContinueUrl] = useState();

    const location = useLocation();

    useEffect(() => {
        const url = new URL(window.location.href);
        const code = url.searchParams.get('oobCode');
        const mode = url.searchParams.get('mode');
        const continueUrl = url.searchParams.get('continueUrl');

        setOOBCode(code);
        setMode(mode);
        setContinueUrl(continueUrl);
    }, [location])

    if (contentLoading) {
        return <PreloaderForm />;
    }

    if (mode === 'verifyEmail') {
        return <VerifyEmail code={oobCode} onCloseRedirect={continueUrl} />
    }

    if (mode === 'resetPassword') {
        return <UpdatePassword code={oobCode} onCloseRedirect={continueUrl} />
    }
}
