var _a = require('lodash'), isUndefined = _a.isUndefined, isNull = _a.isNull;
var ROLES = require('../const/user').ROLES;
var isTgID = require('../helpers/telegram').isTgID;
export function isAdmin(user) {
    var _a;
    return Boolean(user) && ((_a = user === null || user === void 0 ? void 0 : user.roles) === null || _a === void 0 ? void 0 : _a.includes('admin'));
}
export function isModerator(user) {
    var _a;
    return Boolean(user) && ((_a = user === null || user === void 0 ? void 0 : user.roles) === null || _a === void 0 ? void 0 : _a.includes('moderator'));
}
export function isLibrary(user) {
    var _a;
    return Boolean(user) && ((_a = user === null || user === void 0 ? void 0 : user.roles) === null || _a === void 0 ? void 0 : _a.includes('library'));
}
export function hasFullAccess(user) {
    return isAdmin(user) || isModerator(user);
}
export function hasChildrenValidatePermitsByType(permits, nodeType) {
    var _a, _b;
    return (_b = (_a = permits === null || permits === void 0 ? void 0 : permits.children) === null || _a === void 0 ? void 0 : _a.validate) === null || _b === void 0 ? void 0 : _b.includes(nodeType);
}
export function hasDeletePermits(permits, nodeType) {
    var _a, _b;
    return (_b = (_a = permits === null || permits === void 0 ? void 0 : permits.children) === null || _a === void 0 ? void 0 : _a.delete) === null || _b === void 0 ? void 0 : _b.includes(nodeType);
}
export function hasEditPermits(permits, nodeType) {
    var _a, _b;
    return (_b = (_a = permits === null || permits === void 0 ? void 0 : permits.children) === null || _a === void 0 ? void 0 : _a.update) === null || _b === void 0 ? void 0 : _b.includes(nodeType);
}
export function getUserRoles(user, page) {
    if (page === void 0) { page = {}; }
    var result = [];
    var isAnonymous = isNull(user) || !(user === null || user === void 0 ? void 0 : user.id);
    if (isAnonymous) {
        result.push(ROLES.ANONYMOUS);
    }
    else {
        result.push(ROLES.IDENTIFIED);
        if (user === null || user === void 0 ? void 0 : user.id)
            result.push(user.id);
        if (isTgID(user === null || user === void 0 ? void 0 : user.id))
            result.push(ROLES.TELEGRAM);
        if (!isUndefined(page === null || page === void 0 ? void 0 : page._created_by) && !isUndefined(user === null || user === void 0 ? void 0 : user.id) && isAuthor(page, user))
            result.push('owner');
        if (user === null || user === void 0 ? void 0 : user.roles)
            result = result.concat(user.roles);
    }
    return result;
}
export function isRole(id) {
    if (ROLES[id.toUpperCase()])
        return true;
    return false;
}
export function hasRole(list, roles) {
    if (!list)
        return false;
    var result = false;
    if (list.includes('*')) {
        result = true;
    }
    else {
        roles.map(function (role) {
            if (list.includes(role))
                result = true;
        });
    }
    return result;
}
export function isAuthor(page, user) {
    return page._created_by === (user === null || user === void 0 ? void 0 : user.id);
}
export function attachUserData(value, user) {
    var a = value.split(".");
    var obj = a[0];
    var par = value.split(obj + '.')[1];
    if (obj === "template:user" && user) {
        if (par === "id") {
            return user.id || ROLES.ANONYMOUS;
        }
        else if (par === "photo") {
            return user.photoURL;
        }
        else if (par === "messenger") {
            return user.messenger;
        }
        else if (par === "telegram.id") {
            return user.telegram_id;
        }
        else if (par === "name") {
            return user.displayName;
        }
        else if (par === "pname") {
            return user.pName;
        }
        else {
            return (user && par) ? user[par] : null;
        }
    }
    return undefined;
}
