import {useState, useEffect} from 'react';

import Button from '@mui/material/Button';

import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';

import CurrencyFormated from '../../../../Components/CurrencyFormated'
import {useShoppingStore} from '../../../../Stores/shopping';

import './index.scss'

export default function ShopingCart(props) {
    const {price, item, shop, fullWidth, submitButton} = props;

    const {cart, addProduct, removeAllProduct} = useShoppingStore()
    const [selected, setSelected] = useState(false)

    const checkSelected = () => {
        const product = cart.find(i => (i.product.id === item.did || i.product.id === item._did) && i.shop.id === shop.partner.shop)
        setSelected(Boolean(product))
    }

    const onClickHandler = (e) => {
        e.stopPropagation()

        if (selected) {
            removeAllProduct(item) // shop
        } else {
            addProduct(item, shop)
        }
        checkSelected()
    }

    useEffect(() => {
        checkSelected()
    }, [cart]);

    return <div className={selected ? 'ShopingCartButton selected' : 'ShopingCartButton'}>
        <Button
            variant="contained"
            fullWidth={fullWidth}
            disabled={!item.available}
            onClick={onClickHandler}>
                {price ? <><AddShoppingCartIcon /> &nbsp; <CurrencyFormated value={price} currency={shop.partner.config.currency} /> </> : submitButton || 'Замовити'}
        </Button>
    </div>
}
