import {useState, useEffect} from 'react';
import {checkActionCode, getAuth} from "firebase/auth";

import Typography from '@mui/material/Typography';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { NavLink as RouterLink } from "react-router-dom";

import {getClientUrl} from 'ultra/configs/general';
import {LOG_TYPES, HISTORY_USER} from "ultra/const/log";
import {DEFAULT_CITY} from 'ultra/const/general';

import {USER_ACTIONS} from '../../../Reducers/user';

import {error} from '../../../../../Helpers/logs'
import {setPageTitle} from '../../../../../Helpers/router';
import {emailValidated, saveUserHistoryAndNotify} from '../../../Helpers/user'
import {useGlobalCustomContext} from '../../../../../Helpers/context';

import {useToasterStore} from '../../../../../Stores/toster';

import Preloader from '../../../../../Components/Preloader';


export default function VerifyEmail(props) {
    const {code, onCloseRedirect} = props;

    const {showSuccess, showError} = useToasterStore();
    const {userState, userDispatch} = useGlobalCustomContext();

    const [isApplyActionCodeInProgress, setApplyActionCodeInProgress] = useState();

    const auth = getAuth();

    useEffect(() => {
        setPageTitle('Перевірка email');
    }, [])

    const errorHandler = async (action, err, oobCode, email) => {
        setApplyActionCodeInProgress(false);

        let snackbarMessage = err.message || 'Виникла помилка, спробуйте пізніше'

        if (err.code === 'auth/invalid-action-code') {
            snackbarMessage = 'Посилання не валідне'
        }

        if (err.code === 'auth/expired-action-code') {
            snackbarMessage = 'Посилання застаріло'
        }

        showError({
            snackbarMessage,
            onCloseRedirect: onCloseRedirect || getClientUrl(DEFAULT_CITY, '/'),
        });

        const details = {error: err, oobCode};

        // if no email => no record for user
        if (email) {
            await saveUserHistoryAndNotify(email, LOG_TYPES.ERROR, action, details);
        } else {
            await error(LOG_TYPES.ERROR, action, details);
        }
    }

    useEffect(() => {
        setApplyActionCodeInProgress(true);

        // TODO: is launched twice and second run - generate error
        // TODO: ? SAVE code in DB and compare it
        checkActionCode(auth, code)
            .then((info) => {
                const restoredEmail = info['data']['email'];

                // ADD ANIMATION TO LOGIN AVATAR
                userDispatch({type: USER_ACTIONS.USER_UPDATE_IN_PROGRESS});
                emailValidated(restoredEmail)
                    .then(async user => {
                        userDispatch({type: USER_ACTIONS.USER_REFRESH_START});

                        await saveUserHistoryAndNotify(restoredEmail, LOG_TYPES.INFO, HISTORY_USER.EMAIL_VALIDATION_SUCCESS);

                        showSuccess({
                            snackbarMessage: 'Email успішно підтвердженою',
                            onCloseRedirect: onCloseRedirect || getClientUrl(DEFAULT_CITY, '/'),
                            snackbarDuration: 10000
                        });

                        setApplyActionCodeInProgress(false);
                    })
                    .catch(async (err) => {
                        userDispatch({type: USER_ACTIONS.USER_REMOVE});

                        errorHandler(HISTORY_USER.EMAIL_VALIDATION_ERROR, err, code, restoredEmail)
                    })
            })
            .catch(e => { errorHandler(HISTORY_USER.EMAIL_VALIDATION_CODE_ERROR, e, code) })
    }, [])

    return <div className="profileForm">
        <Typography variant='h4' className="pageTitle">
            <RouterLink to="/" className="goBack">
                <ChevronLeftIcon />
            </RouterLink>
            <span>Перевірка email</span>
        </Typography>

        {isApplyActionCodeInProgress && <Preloader />}
    </div>
}
