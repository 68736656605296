import React from 'react';

import {Link as RouterLink} from "react-router-dom";

import './index.scss'

export default function Link(props) {
    const {field, isEditMode} = props;

    if (isEditMode) return null;

    return (<span className='LinkField'>
        <RouterLink to={field.url} target="_blank">
          {field.placeholder}
          <br/><br/>
        </RouterLink>
      </span>)
}
