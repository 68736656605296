import {useEffect, useState} from 'react';

import {useNavigationStore} from '../../../../../../../../Stores/navigation';

// import PreloaderTopPagePicture from '../../../../../../../../Components/PreloaderTopPagePicture';
import PreloaderText from '../../../../../../../../Components/PreloaderText';

import ShopProductsList from '../../../../../../Widgets/ShopProductsList'
import ContentImage from '../../../../../../Widgets/ContentImage'
import CKEditorContent from '../../../../../../Widgets/CKEditorContent'
import LinksLines from '../../../../../../Widgets/LinksLines'
import BreadcrumbsWidget from '../../../../../../Widgets/Breadcrumbs'
import TitleWidget from '../../../../../../Widgets/Title'
import TopPagePicture from '../../../../../../Widgets/TopPagePicture';

import './index.scss'

export default function Template(props) {
    const {content, config, children, permits, breadcrumbs} = props
    
    const {contentLoading} = useNavigationStore()

    const [hasProducts, setHasProducts] = useState();
    const [links, setLinks] = useState();

    useEffect(() => {
        let hasProducts = false;
        const list = {};
        const permits = {};
        const order = [];

        children.order.map(item => {
            if (children.list[item]._type === 'shop-product' || children.list[item]._type === 'shop-alias') {
                hasProducts = true;
            }
    
            if (children.list[item]._type === 'shop-section') {
                list[item] = children.list[item];
                permits[item] = children.permits[item];
                order.push(item);
            }
        })

        setHasProducts(hasProducts)
        setLinks({
            list,
            order, // TODO: add default sort
            permits,
            // showFilters: content.showFilters,
        })
    }, [children])

    if (contentLoading) return <div className='Shop'>
        {/* <PreloaderTopPagePicture /> */}
        <ShopProductsList showSkeleton={true} />
        <PreloaderText />
    </div>

    return <div className="Shop">
        {content.banner && (!content.showPagination || (content.showPagination && children?.pagination?.current === 1))
            && <TopPagePicture cutHeightForDesktop showSkeleton={contentLoading}e image={content.banner}/>}

        <TitleWidget permits={permits} content={content} config={config} breadcrumbs={breadcrumbs} hide={content.hideTitle} />
        <BreadcrumbsWidget links={breadcrumbs} hide={content.hideBreadcrumbs}/>

        {/* TODO: add design */}
        {/* {sections && <Grid className="sectionsList" container spacing={4}>
            {sections.map(section =>
                <Grid item xs={6} md={3} key={section._uri}>
                    <div>
                        <RouterLink to={restorePath(section._uri)}>
                            {section.title}
                        </RouterLink>
                    </div>
                </Grid>
            )}
        </Grid>} */}

        {/* not filter categories */}
        {links?.order?.length > 0 && <LinksLines
            config={config}
            links={links}
        />}

        {hasProducts && <ShopProductsList
            items={children}
            currency={content.partner.config.currency}
            breadcrumbs={breadcrumbs}
            content={content}
            config={config}
        />}

        {content.description && <div className='ShopDescription'>
            {/* <div className='ShopDescriptionTitle'>{content.title}</div> */}
            {/* <div className='ShopDescriptionTitle'>Про магазин</div> */}
            <ContentImage src="thumbnail" image={content.thumbnail} className="thumbnail" />
            <CKEditorContent content={content.description} />
        </div>}
    </div>
}
