import {useEffect, useState} from "react";

import Skeleton from '@mui/material/Skeleton';

import {NOTIFY, NOTIFY_REQUESTS, HISTORY_USER, NOTIFY_NETWORKING, HISTORY_NODE, HISTORY_LIBRARY, NOTIFY_INSURANCE} from "ultra/const/log";

import {useGlobalCustomContext} from '../../../../Helpers/context';

import NotifyFlush from './Templates/General/NotifyFlush';

import HistoryUserFlush from './Templates/HistoryUser/Flush';
import HistoryUserUserFlush from './Templates/HistoryUser/UserFlush';
import HistoryUserUserClean from './Templates/HistoryUser/UserClean';
import HistoryUserContentFlush from './Templates/HistoryUser/ContentFlush';
import HistoryUserCreate from './Templates/HistoryUser/Create';
import HistoryUserContentClean from './Templates/HistoryUser/ContentClean';
import HistoryUserPassUpdateReqEmailSuccess from './Templates/HistoryUser/PassUpdateReqEmailSuccess';
import HistoryUserPassUpdateConfirmSuccess from './Templates/HistoryUser/PassUpdateConfirmSuccess';
import HistoryUserUpdate from './Templates/HistoryUser/Update';
import HistoryUserValidate from './Templates/HistoryUser/Validate';
import HistoryUserBlock from './Templates/HistoryUser/Block';
import HistoryUserBlockCommentChange from './Templates/HistoryUser/BlockCommentChange';
import HistoryUserUnblock from './Templates/HistoryUser/Unblock';

import HistoryUserEmailValidationSuccess from './Templates/HistoryUser/EmailValidationSuccess';
import HistoryUserEmailValidationReqAfterLogin from './Templates/HistoryUser/EmailValidationReqAfterLogin';

import HistoryNodeRemoveNotUserFiles from './Templates/HistoryNode/RemoveNotUserFiles';
import HistoryNodeCreate from './Templates/HistoryNode/Create';
import HistoryNodeRestore from './Templates/HistoryNode/Restore';
import HistoryNodeUpdate from './Templates/HistoryNode/Update';
import HistoryNodeRemove from './Templates/HistoryNode/Remove';
import HistoryNodeMove from './Templates/HistoryNode/Move';
import HistoryNodeRename from './Templates/HistoryNode/Rename';
import HistoryNodeUpdateFileUpload from './Templates/HistoryNode/UpdateFileUpload';
import HistoryNodeRemoveFile from './Templates/HistoryNode/RemoveFile';
import HistoryNodeValidate from './Templates/HistoryNode/Validate';
import HistoryNodeUnvalidate from './Templates/HistoryNode/Unvalidate';
import HistoryNodeWorkerUpdate from './Templates/HistoryNode/WorkerUpdate';
import HistoryNodeWorkerRename from './Templates/HistoryNode/WorkerRename';
import HistoryNodeWorkerRestore from './Templates/HistoryNode/WorkerRestore';
import HistoryNodeWorkerRemove from './Templates/HistoryNode/WorkerRemove';
import HistoryNodeWorkerCreate from './Templates/HistoryNode/WorkerCreate';

import LibraryTransferToLibrary from './Templates/Library/TransferToLibrary';
import LibraryCancel from './Templates/Library/Cancel';
import LibraryReserve from './Templates/Library/Reserve';
import LibraryReserveNotApproved from './Templates/Library/ReserveNotApproved';
import LibraryReserveApproved from './Templates/Library/ReserveApproved';
import LibraryReturn from './Templates/Library/Return';
import LibraryReturnApprove from './Templates/Library/ReturnApprove';

import NetworkingRequest from './Templates/Networking/Request';

import PartnersInsurance from './Templates/Partner/Insurance';
import PartnersFormSubmit from './Templates/Partner/FormSubmit';
import PartnersShopSubmit from './Templates/Partner/ShopSubmit';

import './index.scss'

export default function Notification(props) {
    const {code, values} = props;

    const {userState} = useGlobalCustomContext();

    const [Node, setNode] = useState(<></>)

    useEffect(() => {
        switch (code) {
            // notify
            case NOTIFY.FLUSH:
                setNode(<NotifyFlush />)
                break;

            case HISTORY_USER.FLUSH:
                setNode(<HistoryUserFlush />)
                break;
            
            case HISTORY_USER.CREATE:
                setNode(<HistoryUserCreate />)
                break;

            case HISTORY_USER.USER_FLUSH:
                setNode(<HistoryUserUserFlush />)
                break;

            case HISTORY_USER.USER_CLEAN:
                setNode(<HistoryUserUserClean {...props} />)
                break;

            case HISTORY_USER.CONTENT_FLUSH:
                setNode(<HistoryUserContentFlush />)
                break;

            case HISTORY_USER.CONTENT_CLEAN:
                setNode(<HistoryUserContentClean {...props} />)
                break;

            // clean
            case HISTORY_USER.PASSWORD_UPDATE_REQUEST_EMAIL_SUCCESS:
                setNode(<HistoryUserPassUpdateReqEmailSuccess {...props} />)
                break;

            case HISTORY_USER.PASSWORD_UPDATE_CONFIRM_SUCCESS:
                setNode(<HistoryUserPassUpdateConfirmSuccess {...props} />)
                break;

            case HISTORY_USER.EMAIL_VALIDATION_SUCCESS:
                setNode(<HistoryUserEmailValidationSuccess {...props} />)
                break;

            case HISTORY_USER.EMAIL_VALIDATION_REQUEST_AFTER_LOGIN:
                setNode(<HistoryUserEmailValidationReqAfterLogin {...props} />)
                break;

            // content
            case HISTORY_NODE.REMOVE_NOT_USED_FILES:
                setNode(<HistoryNodeRemoveNotUserFiles {...props} />)
                break;

            case HISTORY_NODE.CREATE:
                setNode(<HistoryNodeCreate {...props} />)
                break;

            case HISTORY_NODE.RESTORE:
                setNode(<HistoryNodeRestore {...props} />)
                break;
                
            case HISTORY_NODE.UPDATE:
                setNode(<HistoryNodeUpdate {...props} />)
                break;
            
            case HISTORY_NODE.REMOVE:
                setNode(<HistoryNodeRemove {...props} />)
                break;

            case HISTORY_NODE.MOVE:
                setNode(<HistoryNodeMove {...props} />)
                break;
                
            case HISTORY_NODE.RENAME:
                setNode(<HistoryNodeRename {...props} />)
                break;

            case HISTORY_NODE.UPDATE_FILE_UPLOAD:
                setNode(<HistoryNodeUpdateFileUpload {...props} />)
                break;

            case HISTORY_NODE.REMOVE_FILE:
                setNode(<HistoryNodeRemoveFile {...props} />)
                break;

            case HISTORY_NODE.VALIDATE:
                setNode(<HistoryNodeValidate {...props} />)
                break;

            case HISTORY_NODE.UNVALIDATE:
                setNode(<HistoryNodeUnvalidate {...props} />)
                break;

            // library
            case HISTORY_LIBRARY.TRANSFER_TO_LIBRARY:
                setNode(<LibraryTransferToLibrary {...props} />)
                break;

            case HISTORY_LIBRARY.CANCEL:
                setNode(<LibraryCancel {...props} />)
                break;

            case HISTORY_LIBRARY.RESERVE:
                setNode(<LibraryReserve {...props} />)
                break;

            case HISTORY_LIBRARY.RESERVE_NOT_APPROVED:
                setNode(<LibraryReserveNotApproved {...props} />)
                break;

            case HISTORY_LIBRARY.RESERVE_APPROVED:
                setNode(<LibraryReserveApproved {...props} />)
                break;

            case HISTORY_LIBRARY.RETURN:
                setNode(<LibraryReturn {...props} />)
                break;

            case HISTORY_LIBRARY.RETURN_APPROVE:
                setNode(<LibraryReturnApprove {...props} />)
                break;

            // workers
            case HISTORY_NODE.WORKER_REMOVE:
                setNode(<HistoryNodeWorkerRemove {...props} />)
                break;

            case HISTORY_NODE.WORKER_CREATE:
                setNode(<HistoryNodeWorkerCreate {...props} />)
                break;

            case HISTORY_NODE.WORKER_UPDATE:
                setNode(<HistoryNodeWorkerUpdate {...props} />)
                break;

            case HISTORY_NODE.WORKER_RENAME:
                setNode(<HistoryNodeWorkerRename {...props} />)
                break;

            case HISTORY_NODE.WORKER_RESTORE:
                setNode(<HistoryNodeWorkerRestore {...props} />)
                break;

            // users
            case HISTORY_USER.UPDATE:
                setNode(<HistoryUserUpdate {...props} />)
                break;

            case HISTORY_USER.VALIDATE:
                setNode(<HistoryUserValidate {...props} />)
                break;

            case HISTORY_USER.BLOCK:
                setNode(<HistoryUserBlock {...props} />)
                break;

            case HISTORY_USER.BLOCK_COMMENT_CHANGE:
                setNode(<HistoryUserBlockCommentChange {...props} />)
                break;

            case HISTORY_USER.UNBLOCK:
                setNode(<HistoryUserUnblock {...props} />)
                break;

            case NOTIFY_NETWORKING.REQUEST:
                setNode(<NetworkingRequest {...props} />)
                break;

            // requests
            case NOTIFY_INSURANCE.SUPPORT:
            case NOTIFY_INSURANCE.REQUEST:
                setNode(<PartnersInsurance {...props} />)
                break;

            case NOTIFY_REQUESTS.FORM_SUBMIT:
                setNode(<PartnersFormSubmit {...props} />)
                break;

            case NOTIFY_REQUESTS.SHOP_SUBMIT:
                setNode(<PartnersShopSubmit {...props} />)
                break;

            default:
                setNode(<span>{code}</span>)
        }
    }, [code, values, userState?.user])

    if (!userState?.user) return <Skeleton variant="rectangular" width={'80%'} height={16} />

    return <div className="NotificationWidget">
        {Node}
        {/* import {isMobile} from "react-device-detect"; */}
        {/* import DateValue from '../../../Content/Widgets/DateValue' */}
        {/* {isMobile && <div><i className="label">
            <DateValue value={values._timestamp} />
        </i></div>} */}
    </div>
}
