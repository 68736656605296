import {useState, useEffect} from 'react'
import { Link as RouterLink } from "react-router-dom";

import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import Icon from '../../../../Components/Icon';
import PreloaderLinksBlocks from '../../../../Components/PreloaderLinksBlocks';

import NotPublishedIcon from '../../Widgets/NotPublishedIcon';

import './index.scss'

export default function LinksList(props) {
    const {links, showSkeleton} = props

    if (showSkeleton) return <PreloaderLinksBlocks/>

    return !showSkeleton && links && Object.keys(links).length > 0 && <ul className="LinksList">
        {Object.keys(links).map(link =>
            <li
                key={link.slice(1)}
                className={links[link]?.description ? '' : 'no-description'}
            >                    
                {links[link] && <RouterLink className="item" to={link.slice(1)}>
                    {links[link].icon && <span className="icon notPublishedWrap">
                        <Icon mui={links[link].icon} />
                        {/* {!links[link].icon && <Icon mui="subject" />} */}
                        {!links[link]._published && <NotPublishedIcon />}
                    </span>}

                    <span className="information">
                        {links[link].title && <span className="title">{links[link].title}</span>}
                        {links[link].description && <span className="description">{links[link].description}</span>}
                    </span>

                    <NavigateNextIcon className="arrow" />
                </RouterLink>}
            </li>)}
    </ul>
}
