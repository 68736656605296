import {useState} from 'react';

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import SmartToyIcon from '@mui/icons-material/SmartToy';

import TextField from '@mui/material/TextField';

import AdaptiveDialog from '../../../../Components/Adaptive/Dialog';

import {sendTelegramNotification} from '../../Helpers/bot';

// import {getTelegramMode} from '../../../../Helpers/telegram';

import {useToasterStore} from '../../../../Stores/toster';

const title = "Нотифікація в бот";

export default function BotNotificationButton(props) {
    const {id, fullWidth} = props;

    const {showError} = useToasterStore();

    const [message, setMessage] = useState('');
    const [open, setOpen] = useState(false);

    const handler = () => {
        setOpen(true);
    }

    const hidePopup = () => {
        setOpen(false);
    }

    const textareaHandleChange = (e) => {
        setMessage(e.target.value)
    }

    const submitHandler = () => {
        // const bot = getTelegramMode();
        sendTelegramNotification({id, message})
            .then(() => {
                setOpen(false);
                setMessage('');
            })
            .catch(e => {
                showError({
                    snackbarMessage: e.message
                })
                setOpen(false);
            })
    }

    if (!id) return <></>

    return <>
        <Button startIcon={<SmartToyIcon />} variant="outlined" fullWidth={fullWidth} target="_blank" onClick={handler}>{title}</Button>
        <AdaptiveDialog open={open} onClose={hidePopup} title={title}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField minRows={4} multiline fullWidth onChange={textareaHandleChange} value={message}/>
                    <div className='fieldNotes'>Можна надіслати простий текст, html форматування, emoji та посилання на телеграм аккаунти (@account_name)</div>
                </Grid>
                <Grid item xs={12}>
                    <Button variant="contained" fullWidth onClick={submitHandler}>Відправити</Button>
                </Grid>
            </Grid>
        </AdaptiveDialog>
    </>
}
