import {useState} from "react";
import {isString} from "lodash";

import Button from '@mui/material/Button';
import TelegramIcon from '@mui/icons-material/Telegram';

import {MESSENGER_COLORS} from 'ultra/const/messengers';
import {getMessengerLink} from 'ultra/helpers/messengers';
import {isTgID} from "ultra/helpers/telegram";

import UserMessageLink from '../UserMessageLink'

export default function MessageToReader(props) {
    const {reading} = props;

    const [title] = useState('Написати читачу')

    // TODO: remove after Prostir release
    // remove it and always use internal messenger ?
    if (isTgID(reading)) {
        return  <Button
                    startIcon={<TelegramIcon sx={{color: MESSENGER_COLORS['telegram']}} />}
                    variant="outlined"
                    fullWidth
                    className="UserMessageLink"
                    target="_blank"
                    href={getMessengerLink(reading)}>
                        {title}
                </Button>
    }
    else if (isString(reading)) {
        return <UserMessageLink variant="button" label={title} id={reading} fullWidth />
    }
    else {
        return <></>
    }
}
