import {isUndefined} from "lodash";
import {useEffect, useState} from "react";
import {isMobile} from "react-device-detect";
import {useNavigate} from "react-router-dom";

import {getTgID} from 'ultra/helpers/telegram';
// import {isEmptyObj} from 'ultra/helpers/utils';
import {DEFAULT_CITY} from 'ultra/const/general';
import {getClientCityTimezone} from 'ultra/configs/general';
import {restorePath} from "ultra/helpers/route";
import {getClientCity} from 'ultra/configs/general';
import {isLibrary} from 'ultra/helpers/auth';
import {hasFullAccess} from 'ultra/helpers/auth';

import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import Button from '@mui/material/Button';

import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import ArrowCircleUpOutlinedIcon from '@mui/icons-material/ArrowCircleUpOutlined';

import {getFormatedDate, timestampToDate} from 'ultra/helpers/date'

import {useConfigStore} from '../../../../../../../../../Stores/config';
import {useBackdropPreloaderStore} from '../../../../../../../../../Stores/backdropPreloader';

import PriceWidget from '../../../../../../../Widgets/Price';

import {useGlobalCustomContext} from '../../../../../../../../../Helpers/context';
import {getTelegramLogedUser} from '../../../../../../../../Profile/Helpers/bot';
import {getTelegramUser, getTelegramUserId, setTelegramUser} from '../../../../../../../../../Helpers/telegram';
import {isYoursAccount} from '../../../../../../../../../Helpers/user';

import {USER_ACTIONS} from '../../../../../../../../Profile/Reducers/user';

import {ownerApproveReserveBook, ownerNotApproveReserveBook, reserveCancel, ownerApproveReturnBook} from '../../../../../../../../../Helpers/library';
import {getBookStatuscode} from '../..//../../../../../Helpers/library'

import BookIsReading from '../BookIsReading';
import MessageToOwner from '../MessageToOwner';
import MessageToReader from '../../../../../../../Widgets/MessageToReader';
import ReaderDetails from '../ReaderDetails';

import './index.scss';

function Queue() {
    return <>
        {/* <Button variant="outlined" fullWidth={isMobile} onClick={onAddToQueue}>Встати в чергу</Button> */}
        {/* <Button variant="outlined" fullWidth={isMobile} onClick={onRemoveFromQueue}>Вийти з черги</Button> */}
    </>
}

function libraryLimitsReached(user) {
    let amount = 0
    if (user?.library) {
        Object.keys(user.library).map(city => {
            amount += user.library[city].reading
        })
    }

    return amount >= 2
}

export default function ShowActions(props) {
    const {belongsToLibrary, onGetBook, onReturnBook, content} = props;

    const navigate = useNavigate();

    const [city] = useState(getClientCity(window) || DEFAULT_CITY)

    const {showBackdropPreloader, hideBackdropPreloader} = useBackdropPreloaderStore()
    const {userState, userDispatch} = useGlobalCustomContext();

    const [Action, setAction] = useState(<Skeleton variant="rectangular" width='100%' height={56} />);

    const {configs} = useConfigStore();

    const [timeZone, setTimeZone] = useState()

    async function updateCurrentPageContent() {
        if (getTelegramUser()) {
            const tgUser = await getTelegramLogedUser()
            setTelegramUser(tgUser)
        }

        navigate(restorePath(content._uri))
        hideBackdropPreloader()
        userDispatch({type: USER_ACTIONS.USER_REFRESH_START})
    }

    const onOwnerReserveApprove = () => {
        showBackdropPreloader()

        ownerApproveReserveBook(city, content._uri)
            .then(updateCurrentPageContent)
            // add catch error
    }

    const onOwnerReserveNotApprove = () => {
        showBackdropPreloader()
        ownerNotApproveReserveBook(city, content._uri)
            .then(updateCurrentPageContent)
            // add catch error
    }

    const onCancelReservation = () => {
        showBackdropPreloader()
        reserveCancel(city, content._uri)
            .then(updateCurrentPageContent)
            // add catch error
    }

    const onOwnerReturnApprove = () => {
        showBackdropPreloader();
        ownerApproveReturnBook(city, content._uri)
            .then(updateCurrentPageContent)
            // add catch error
    }

    // const onAddToQueue = () => {
    //     if (!user || isEmptyObj(user)) {
    //         showPromt({
    //             snackbarMessage: 'Щоб мати можливість встати в чергу вам потрібно авторизуватись',
    //             onApproveHandler: () => {
    //                 closeToaster();
    //                 hideBackdropPreloader();

    //                 userDispatch({type: USER_ACTIONS.LOGIN})
    //             },
    //             onCloseHandler: () => {
    //                 closeToaster();
    //                 hideBackdropPreloader();
    //             }
    //         })
    //     } else {
    //         // !!!!
    //     }
    // }

    useEffect(() => {
        setTimeZone(getClientCityTimezone(configs, city));
    }, [configs])

    // if (!content) return <></>
    useEffect(() => {
        const isOwner = isYoursAccount(userState, content?._created_by);
        const telegram = getTelegramUser();

        if (!isUndefined(belongsToLibrary)) {
            // TODO: remove after Prostir release
            // only uid will be used
            const isReader =
                isYoursAccount(userState, content?._reading_by)
                || (telegram && getTelegramUserId() === getTgID(content?._reading_by));

            // auth process
            if (userState?.userLoginInProgress) {
                setAction(<Skeleton variant="rectangular" width={isMobile ? '100%' : '160px'} height={56} />)
            }

            // book is reserved
            else if (content?._reading_request) {
                if (isOwner) {
                    setAction(
                        <div className="actionInfo reserved">
                            <div className="text">
                                <p>Книга заброньована</p>
                                {!belongsToLibrary && <p>
                                    Обговоріть передачу книги {content.deposit && <span>та сплату завдатку</span>}
                                </p>}
                            </div>

                            <Grid container spacing={2}>
                                <Grid item xs={12} md={4}>
                                    <ReaderDetails book={content} fullWidth />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <Button startIcon={<CheckCircleOutlineOutlinedIcon />} className="column successButton" variant="outlined" fullWidth onClick={onOwnerReserveApprove}>Підтвердити</Button>
                                    </Grid>
                                <Grid item xs={12} md={4}>
                                    <Button startIcon={<CancelOutlinedIcon />} className="column errorButton" variant="outlined" fullWidth onClick={onOwnerReserveNotApprove}>Відмінити</Button>
                                </Grid>
                            </Grid>

                        </div>
                    )
                }
                else if (isReader) {
                    setAction(
                        <div className="actionInfo reserved">
                            <div className="text">
                                <p>Книга заброньована</p>

                                {!belongsToLibrary && <p>
                                    Обговоріть з власником як отримати книгу
                                    {content.deposit && <> та залишити завдаток</>}
                                </p>}

                                {belongsToLibrary && <p>
                                    Після перевірки сплати депозиту з вами зв'яжеться бібліотекар
                                </p>}
                            </div>
                            <Grid container spacing={2}>
                                {/* TODO: remove after Prostir release */}
                                {!belongsToLibrary && <Grid item xs={12} md={6}>
                                    <MessageToOwner belongsToLibrary={belongsToLibrary} className="column" book={content}/>
                                </Grid>}
                                <Grid item xs={12} md={belongsToLibrary ? 12 : 6}>
                                    <Button startIcon={<CancelOutlinedIcon />} className="column errorButton" variant="outlined" fullWidth onClick={onCancelReservation}>Відмінити</Button>
                                </Grid>
                            </Grid>
                        </div>
                    )
                }
                // else if (!isEmptyObj(userState?.user) || isNull(userState?.user)) {
                else {
                    setAction(
                        <div className="actionInfo booked">
                            <div className="text">
                                <p>Книга заброньована іншим користувачем</p>
                            </div>

                            {/* admin buttons */}
                            {hasFullAccess(userState?.user) && <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <ReaderDetails book={content} fullWidth />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Button startIcon={<CancelOutlinedIcon />} className="column errorButton" variant="outlined" fullWidth onClick={onOwnerReserveNotApprove}>Відмінити</Button>
                                </Grid>
                            </Grid>}

                            <Queue />
                        </div>
                    )
                }
            }

            // book in return process
            else if (content?._reading_finished) {
                if (isOwner) {
                    setAction(
                        <div className="actionInfo return">
                            <div className="text">
                                <p>
                                    Книга була прочитана
                                </p>
                                {content.deposit && <p>
                                    Поверніть завдаток <PriceWidget value={content.deposit} /> та отримайте книгу
                                </p>}
                            </div>

                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <ReaderDetails book={content} fullWidth />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Button startIcon={<CheckCircleOutlineOutlinedIcon />} variant="outlined" className="successButton" fullWidth onClick={onOwnerReturnApprove}>Прийняти книгу</Button>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <MessageToReader reading={content._reading_by} />
                                </Grid>
                            </Grid>
                        </div>
                    )
                }
                else if (isReader) {
                    setAction(
                        <div className="actionInfo return">
                            <div className="text">
                                <p>Запит на повернення книги відправлено</p>

                                {!belongsToLibrary && <p>
                                    Обговоріть з власником як повернути книгу
                                    {content.deposit && <> та отримати завдаток</>}
                                </p>}

                                {belongsToLibrary && <p>
                                    Очікуйте на повідомлення від бібліотекаря
                                </p>}
                            </div>
                            {!belongsToLibrary && <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <MessageToOwner belongsToLibrary={belongsToLibrary} book={content}/>
                                </Grid>
                            </Grid>}
                        </div>
                    )
                }
                // else if (!isEmptyObj(userState?.user) || isNull(userState?.user)) {
                else {
                    setAction(<div className="actionInfo">
                        <div className="text">
                            {!belongsToLibrary && <p>Книга була прочитана і повертається до власника</p>}
                            {belongsToLibrary && <p>Книга була прочитана і повертається до бібліотеки</p>}
                        </div>

                        {/* admin buttons */}
                        {hasFullAccess(userState?.user) && <Grid container spacing={2}>
                            <Grid item xs={12} md={12}>
                                <ReaderDetails book={content} fullWidth />
                            </Grid>
                        </Grid>}

                        <Queue />
                    </div>)
                }
            }

            // book is taken
            else if (content?._reading_from && !content?._reading_finished) {
                if (isOwner) {
                    setAction(
                        <div className={`actionInfo reading ${getBookStatuscode(content)}`}>
                            <BookIsReading book={content}/>
                        </div>
                    )
                }
                else if (isReader) {
                    setAction(<div className="actionInfo reading">
                        <div className="text">
                            <p>Ви взяли книгу {getFormatedDate(timestampToDate(content?._reading_from), timeZone)}</p>
                            <p>Поверніть її не пізніше {getFormatedDate(timestampToDate(content?._reading_till), timeZone)}</p>
                        </div>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Button startIcon={<ArrowCircleUpOutlinedIcon />} variant="outlined" className="successButton" fullWidth onClick={onReturnBook}>Повернути книгу</Button>
                            </Grid>
                        </Grid>
                    </div>)
                }
                // else if (!isEmptyObj(userState?.user) || isNull(userState?.user)) {
                else {
                    setAction(<div className={`actionInfo reading ${getBookStatuscode(content)}`}>
                        <BookIsReading book={content}/>

                        <Queue />
                    </div>)
                }
            }

            else {
                if (isOwner) {
                    setAction(<></>)
                }
                // if (userState?.user || isNull(userState?.user))
                else {
                    if (isLibrary(userState?.user)) {
                        setAction(<div className="actionInfo"><div className="text">Бібліотекар не може взяти книгу на читання</div></div>)
                    }
                    else if (libraryLimitsReached(telegram || userState?.user)) {
                        setAction(<div className="actionInfo info"><div className="text">Ви можете взяти не більше 2х книг</div></div>)
                    }
                    else {
                        setAction(<Button variant="contained" className="getBookBotton" onClick={onGetBook}>Взяти книгу</Button>)
                    }
                }
            }
        }
    }, [content, userState?.user, belongsToLibrary])   // , userState?.userLoginInProgress

    return <div className='actions'>
        {Action}
    </div>
}
