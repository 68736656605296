import React, {useEffect, useState} from 'react';

import {useNavigate} from "react-router-dom";

import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import BlockOutlinedIcon from '@mui/icons-material/BlockOutlined';
import ReplayOutlinedIcon from '@mui/icons-material/ReplayOutlined';
import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined';
import HistoryIcon from '@mui/icons-material/History';
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';

import {useGlobalCustomContext} from '../../../../Helpers/context';
import {validateUser, unblockUser} from '../../Helpers/admin';
import {cleanUsersHistory} from '../../Helpers/history';

import InlineActions from '../../../Content/Widgets/InlineActions';
import {useToasterStore} from '../../../../Stores/toster';
import {useBackdropPreloaderStore} from '../../../../Stores/backdropPreloader';

import {USER_ACTIONS} from '../../Reducers/user';
import {NODE_ACTIONS} from '../../../Content/Reducers/node';

import './index.scss';

export default function Render(options) {
    const actions = getActions(options);
    if (actions.length === 0) return null;
    return <UserInlineActions {...options} actions={actions} />;
}

function getActions(options) {
    const {user, hideHistory, showCleanHistory, hasFullAccess} = options;

    const actions = [];

    if (user) {
        const hasCheckAction = user._not_checked;

        const hasUnblockAction = user._blocked;
        const hasBlockAction = !user._blocked;
        const hasEditBlockAction = user.__blocked_comment;

        // Підтвердити
        if (hasCheckAction) {
            actions.push('check')
        }

        // Розблукувати
        if (hasUnblockAction) {
            actions.push('unblock')
        }

        // Заблокувати
        if (hasBlockAction) {
            actions.push('block')
        }

        // Редагувати коментар блокування
        if (hasEditBlockAction) {
            actions.push('editblockcomment')
        }

        // історія
        if (!hideHistory && hasFullAccess && user?.id) {
            actions.push('history')
        }

        if (showCleanHistory && user?.id) {
            actions.push('clean')
        }
    }

    return actions;
}

function UserInlineActions(props) {
    const {actions, user, direction, unfolded, className} = props;

    const {userDispatch, nodeDispatch} = useGlobalCustomContext();

    const {showPromt, closeToaster} = useToasterStore();
    const {hideBackdropPreloader, showBackdropPreloader} = useBackdropPreloaderStore();

    const [list, setList] = useState([])

    const navigate = useNavigate();

    function userCheckAction() {
        showPromt({
            snackbarMessage: `Підтвердити доступ?`,
            // snackbarDuration: 10000,
            onApproveHandler: async () => {
                await validateUser(user.id);

                userDispatch({type: USER_ACTIONS.USER_REFRESH_START})

                closeToaster();
                hideBackdropPreloader();
            }
        })     
    }

    function userUnblockAction() {        
        showPromt({
            snackbarMessage: `Користувач був заблокований. ${user.__blocked_comment ? `Причина: ${user.__blocked_comment}.` : ''} Бажаєте продовжити?`,
            // snackbarDuration: 10000,
            onApproveHandler: async () => {
                closeToaster();
                
                showBackdropPreloader();

                await unblockUser(user.id);
                nodeDispatch({type: NODE_ACTIONS.UPDATE_PAGE_CONTENT_START});

                hideBackdropPreloader();
            }
        })
    }

    function userBlockAction() {
        userDispatch({type: USER_ACTIONS.ADMIN.BLOCK, data: user})
    }

    useEffect(() => {
        const list = actions.map(id => {
            let result;
            switch(id) {
                case 'check':
                    result = {
                        id: 'check',
                        icon: <CheckCircleOutlineOutlinedIcon className='Icon successIcon' />,
                        onClick: userCheckAction
                    }
                    break;

                case 'unblock':
                    result = {
                        id: 'unblock',
                        icon: <ReplayOutlinedIcon className='Icon successIcon' />,
                        onClick: userUnblockAction
                    }
                    break;

                case 'block':
                    result = {
                        id: 'block',
                        icon: <BlockOutlinedIcon className='Icon errorIcon' />,
                        onClick: userBlockAction
                    }
                    break;

                case 'editblockcomment':
                    result = {
                        id: 'editblockcomment',
                        icon: <CommentOutlinedIcon className='Icon' />,
                        onClick: userBlockAction
                    }
                    break;

                case 'history':
                    result = {
                        id: 'history',
                        icon: <HistoryIcon className='Icon' />,
                        onClick: () => {
                            navigate(`/profile/history/user/${user?.id}`)
                        }
                    }
                    break;

                case 'clean':
                    result = {
                        id: 'clean',
                        icon: <CleaningServicesIcon className='Icon errorIcon' />,
                        onClick: () => {
                            showPromt({
                                snackbarMessage: 'Бажаєте очистити історію користувача?',
                                snackbarDuration: 10000,
                                onApproveHandler: () => {
                                    closeToaster();
    
                                    cleanUsersHistory(user.id)
                                        .then(() => {
                                            hideBackdropPreloader();
                                            userDispatch({type: USER_ACTIONS.USER_REFRESH_START})
                                        })
                                        .catch(() => {
                                            hideBackdropPreloader();
                                        })
                                },
                            })
                        }
                    }
                    break;
            }
            return result;
        });
        setList(list);
    }, [])

    return <InlineActions actions={list} className={className} direction={direction} unfolded={unfolded} />
}
