import {getShopDetails} from 'ultra/helpers/shop';

import {useNavigationStore} from '../../../../../../../Stores/navigation';

import LinksLines from '../../../../../Widgets/LinksLines'
import BreadcrumbsWidget from '../../../../../Widgets/Breadcrumbs';
import TitleWidget from '../../../../../Widgets/Title';
import ShopingCart from '../../../../../Widgets/ShopingCart'
import FilterChip from '../../../../../Widgets/FilterChip';

import './index.scss'

export default function Template(props) {
    const {content, children, config, permits, breadcrumbs} = props

    const {contentLoading} = useNavigationStore()

    return <div className="ServicesTemplate">
        <TitleWidget permits={permits} content={content} config={config} breadcrumbs={breadcrumbs} hide={content.hideTitle}/>
        <BreadcrumbsWidget links={breadcrumbs} hide={content.hideBreadcrumbs}/>

        <LinksLines
            config={config}

            permits={permits}
            showFilters={config?.showFilters || content?.showFilters}
            showSkeleton={contentLoading}
            links={children}

            title={item => <div className='ServiceTitle'>
                <div>{item.title}</div>
                <div className='SaleItemPrice'>
                    <ShopingCart
                        item={item}
                        price={item.price}
                        shop={getShopDetails(item, breadcrumbs)?.shop}
                    />
                </div>
            </div>}

            label={(item) => <div className='ServiceCategories'>
                {item?.category?.map(c => <FilterChip value={c} config={config.categories} />)}
            </div>}
        />
    </div>
}
