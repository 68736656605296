import {useState} from 'react';
import Typography from '@mui/material/Typography';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { NavLink as RouterLink } from "react-router-dom";

import {DEFAULT_CITY} from 'ultra/const/general';
import {getClientUrl} from 'ultra/configs/general';
import {LOG_TYPES, HISTORY_USER} from "ultra/const/log";

// inMemoryPersistence, setPersistence, signInWithEmailAndPassword
import {verifyPasswordResetCode, confirmPasswordReset, getAuth} from "firebase/auth";

import FormContainer from '../../../../../Components/Form/FormContainer'

import {getModule} from '../../../../../Helpers/module'
import {error} from '../../../../../Helpers/logs'

import {useToasterStore} from '../../../../../Stores/toster';
import {useBackdropPreloaderStore} from '../../../../../Stores/backdropPreloader';

import {signIn, saveUserHistoryAndNotify} from '../../../Helpers/user';
import {useGlobalCustomContext} from '../../../../../Helpers/context';
import {setPageTitle} from '../../../../../Helpers/router';

import {USER_ACTIONS} from '../../../Reducers/user';

import {useEffect} from 'react';

export default function UpdatePassword(props) {
    const {code, onCloseRedirect} = props;

    const {hideBackdropPreloader} = useBackdropPreloaderStore()
    const {showSuccess, showError} = useToasterStore();
    const {userDispatch} = useGlobalCustomContext();

    const [profile, setProfile] = useState();
    const [email, setEmail] = useState();

    const auth = getAuth();

    useEffect(() => {
        setPageTitle('Зміна паролю')

        getModule('profile').then(setProfile)
    }, [])

    useEffect(() => {
        verifyPasswordResetCode(auth, code)
            .then(async email => {
                setEmail(email);

                // await saveUserHistoryAndNotify(email, LOG_TYPES.INFO, HISTORY_USER.PASSWORD_UPDATE_CODE_VERIFY_SUCCESS, {oobCode: code});
            })
            .catch(async err => {
                let snackbarMessage = 'Виникла помилка, спробуйте пізніше'

                if (err.code === 'auth/invalid-action-code') {
                    snackbarMessage = 'Посилання не валідне, створіть нове'
                }
                
                if (err.code === 'auth/expired-action-code') {
                    snackbarMessage = 'Посилання застаріло, створіть нове'
                }

                showError({
                    snackbarMessage,
                    onCloseRedirect: onCloseRedirect || `/profile/reset`
                });

                // no information about email here, so no record for user
                await error(LOG_TYPES.ERROR, HISTORY_USER.PASSWORD_UPDATE_CODE_VERIFY_ERROR, {oobCode: code, error: err});
            })
    }, [])

    const onUpdatePasswordSubmit = (data) => {
        confirmPasswordReset(auth, code, data.password)
            .then(async () => {            
                await saveUserHistoryAndNotify(email, LOG_TYPES.INFO, HISTORY_USER.PASSWORD_UPDATE_CONFIRM_SUCCESS, {oobCode: code});

                userDispatch({type: USER_ACTIONS.USER_UPDATE_IN_PROGRESS});
                signIn(email, data.password)
                    .then(async (user) => {
                        userDispatch({type: USER_ACTIONS.USER_REFRESH_START});

                        // await saveUserHistoryAndNotify(email, LOG_TYPES.INFO, HISTORY_USER.PASSWORD_UPDATE_AUTOLOGIN_SUCCESS);

                        userDispatch({type: USER_ACTIONS.USER_REFRESH_START});
                        
                        showSuccess({
                            snackbarMessage: 'Пароль успішно оновлено',
                            onCloseRedirect: onCloseRedirect || getClientUrl(DEFAULT_CITY, '/'),
                            snackbarDuration: 10000
                        });
                    })
                    .catch(async err => {
                        userDispatch({type: USER_ACTIONS.USER_REMOVE});

                        showError({snackbarMessage: 'Помилка авторизації після оновлення пароля'})

                        await saveUserHistoryAndNotify(email, LOG_TYPES.ERROR, HISTORY_USER.PASSWORD_UPDATE_AUTOLOGIN_ERROR, err);
                    })
            }).catch(async (err) => {
                showError({
                    snackbarMessage: 'Помилка оновлення пароля'
                });

                await saveUserHistoryAndNotify(email, LOG_TYPES.ERROR, HISTORY_USER.PASSWORD_UPDATE_CONFIRM_ERROR, {oobCode: code, error: err});

                hideBackdropPreloader();
            });
    }

    return <div className="profileForm">
        <Typography variant='h4' className="pageTitle">
            <RouterLink to="/" className="goBack">
                <ChevronLeftIcon />
            </RouterLink>
            <span>Зміна паролю</span>
        </Typography>

        {email && <p className='resetDescription'>Оновлення паролю для аккаунту, зареєестрованного на <i>{email}</i></p>}

        {profile?.restorePasswordUpdateForm && <FormContainer
            config={profile.restorePasswordUpdateForm}
            submitHandler={onUpdatePasswordSubmit}
        />}
    </div>
}
