export var normalizeRoute = function (path, removeLastCharacter, isReversedByDefault) {
    if (removeLastCharacter === void 0) { removeLastCharacter = true; }
    if (isReversedByDefault === void 0) { isReversedByDefault = false; }
    if (!path)
        return null;
    var reversed = path.indexOf('\\') > -1;
    if (isReversedByDefault)
        reversed = true;
    var delimiter = reversed ? '\\' : '\/';
    var doubleDelimiter = reversed ? '\\\\' : '\/\/';
    var relativePathMakrer = reversed ? '..\\' : '..\/';
    if (path.indexOf(relativePathMakrer) > -1 || path.indexOf('~') > -1) {
        var err = "path ".concat(path, " has relative part");
        return { path: path, err: err };
    }
    if (path.indexOf(doubleDelimiter) > -1) {
        var err = "path ".concat(path, " is incorrect, it has double slashes");
        return { path: path, err: err };
    }
    if (path[0] !== delimiter) {
        path = delimiter + path;
    }
    if (removeLastCharacter && path.length > 1 && path[path.length - 1] === delimiter) {
        path = path.substring(0, path.length - 1);
    }
    return path;
};
export function removeRootFromPath(path) {
    var result = path;
    if (path[0] === '/') {
        result = path.substring(1);
    }
    if (path[0] === '\\') {
        result = path.substring(1);
    }
    return result;
}
export function revertPath(path) {
    if (!path)
        return path;
    return path.replaceAll('/', '\\');
}
export function restorePath(path) {
    if (!path)
        return '/';
    return path.replaceAll('\\', '/');
}
export function getParentPath(path) {
    if (!path)
        return null;
    if (path.length < 2)
        return null;
    if (path.indexOf('/') === -1 && path.indexOf('\\') === -1)
        return null;
    var reversed = path.indexOf('\\') > -1;
    var delimiter = reversed ? '\\' : '/';
    var regDelimiter = reversed ? /\\/g : /\//g;
    if (path.match(regDelimiter).length < 2)
        return delimiter;
    return path.split(delimiter).slice(0, -1).join(delimiter);
}
