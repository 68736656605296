import React, {useEffect, useState, useRef} from 'react';
import {useLocation, useLoaderData, useNavigation} from "react-router-dom";

import {DEFAULT_CITY} from 'ultra/const/general';
import {isEmptyObj} from 'ultra/helpers/utils';
import {getClientCity, getCityConfig} from 'ultra/configs/general';
import {getShopDetails} from 'ultra/helpers/shop';
import {normalizeRoute} from 'ultra/helpers/route';
import {isAdmin} from 'ultra/helpers/auth';

import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';

// stores
import {useConfigStore} from '../../Stores/config'
import {useContentStore} from '../../Stores/content'

// containers
import SectionTemplate from './Templates/Containers/Section/base/SectionTemplate'

import NodeLinesTemplate from './Templates/Nodes/System/Node/view/NodeLinesTemplate'
import NodeLinesCompactTemplate from './Templates/Nodes/System/Node/view/NodeLinesCompactTemplate'
import NodeTileTemplate from './Templates/Nodes/System/Node/view/NodeTileTemplate'
import NodeTableTemplate from './Templates/Nodes/System/Node/view/NodeTableTemplate'
import AfficheTileTemplate from './Templates/Nodes/System/Node/view/AfficheTileTemplate'

import LibraryTemplate from './Templates/Nodes/System/Node/custom/LibraryTemplate'
import ChatsTemplate from './Templates/Nodes/System/Node/custom/ChatsTemplate'
import ShopsTileTemplate from './Templates/Nodes/System/Node/custom/ShopsTileTemplate'

import LinksTemplate from './Templates/Containers/Links/base/LinksTemplate'
import SectionTileTemplate from './Templates/Containers/Section/view/SectionTileTemplate'

import SectionGalleryTemplate from './Templates/Containers/Section/view/SectionGalleryTemplate'
import SalesTemplate from './Templates/Containers/Section/custom/SalesTemplate'
import ServicesTemplate from './Templates/Containers/Section/custom/ServicesTemplate'

// nodes
import NodeTemplate from './Templates/Nodes/System/Node/base/NodeTemplate'

import AliasTemplate from './Templates/Nodes/System/Alias/base/AliasTemplate'
import FormTemplate from './Templates/Nodes/System/Form/base/FormTemplate'

import SaleTemplate from './Templates/Nodes/Custom/Sale/base/SaleTemplate'
import RecommendationTemplate from './Templates/Nodes/Custom/Recommendation/base/RecommendationTemplate'
import LocationTemplate from './Templates/Nodes/Custom/Location/base/LocationTemplate'
import ActivityTemplate from './Templates/Nodes/Custom/Service/base/ServiceTemplate'
import CitiesTemplate from './Templates/Nodes/Custom/Cities/base/CitiesTemplate'

import NetworkingTemplate from './Templates/Nodes/Custom/Networking/base/NetworkingTemplate'

import AnalogueTemplate from './Templates/Nodes/Custom/Analogue/base/AnalogueTemplate'
import DoctorTemplate from './Templates/Nodes/Custom/Doctor/base/DoctorTemplate'
import ClinicTemplate from './Templates/Nodes/Custom/Clinic/base/ClinicTemplate'

import ChatTemplate from './Templates/Nodes/Custom/Chat/base/ChatTemplate'
import UrlTemplate from './Templates/Nodes/Custom/Url/base/UrlTemplate'

import BookTemplate from './Templates/Nodes/Custom/Book/base/BookTemplate'

// complex
import WikiTemplate from './Templates/Complex/Wiki/Wiki/base/WikiTemplate'
import WikiNoteTemplate from './Templates/Complex/Wiki/Note/base/WikiNoteTemplate'

import NewsTemplate from './Templates/Complex/News/News/base/NewsTemplate'
import NewsListTemplate from './Templates/Complex/News/NewsList/base/NewsListTemplate'

import EventsTemplate from './Templates/Complex/Events/Events/base/EventsTemplate'
import EventTemplate from './Templates/Complex/Events/Event/base/EventTemplate'
import TourTemplate from './Templates/Complex/Events/Tour/base/TourTemplate'
import RetreatTemplate from './Templates/Complex/Events/Retreat/base/RetreatTemplate'


// modules
import ShopTemplate from './Templates/Modules/Shop/Shop/base/ShopTemplate'

import ShopSectionTemplate from './Templates/Modules/Shop/Section/base/ShopSectionTemplate'
import ShopSectionTableTemplate from './Templates/Modules/Shop/Section/custom/ShopSectionTableTemplate'

import ShopProductTemplate from './Templates/Modules/Shop/Product/base/ShopProductTemplate'
import JeweleryProductTemplate from './Templates/Modules/Shop/Product/custom/JeweleryProductTemplate'

import InsuranceTemplate from './Templates/Modules/Insurance/base/InsuranceTemplate'

// widgets
import NodeActions from '../Content/Widgets/NodeActions'

import {useGlobalCustomContext} from '../../Helpers/context';

import {getNodeContent} from '../../Helpers/content';

import {NODE_ACTIONS} from './Reducers/node';

import {setBodyClass, setPageTitle} from '../../Helpers/router';

import './index.scss';

const Templates = {
    // containers
    section: SectionTemplate,
    links: LinksTemplate,

    // view:
    tile: NodeTileTemplate,
    lines: NodeLinesTemplate,
    linesCompact: NodeLinesCompactTemplate,
    table: NodeTableTemplate,
    affiche: AfficheTileTemplate,

    library: LibraryTemplate,
    chats: ChatsTemplate,

    sectionTile: SectionTileTemplate,
    galleries: SectionGalleryTemplate,

    sales: SalesTemplate,
    services: ServicesTemplate,

    // nodes
    node: NodeTemplate,
    alias: AliasTemplate,
    form: FormTemplate,

    newsList: NewsListTemplate,
    news: NewsTemplate,

    networking: NetworkingTemplate,
    sale: SaleTemplate,
    location: LocationTemplate,
    recommendation: RecommendationTemplate,

    service: ActivityTemplate,

    cities: CitiesTemplate,
    shopsTile: ShopsTileTemplate,

    analogue: AnalogueTemplate,
    doctor: DoctorTemplate,
    clinic: ClinicTemplate,

    url: UrlTemplate,
    chat: ChatTemplate,

    // modules
    insurance: InsuranceTemplate,

    shop: ShopTemplate,
    shopSection: ShopSectionTemplate,
    shopProduct: ShopProductTemplate,

    shopSectionTable: ShopSectionTableTemplate,

    jewelery: JeweleryProductTemplate,

    // complex
    wiki: WikiTemplate,
    wikiNote: WikiNoteTemplate,

    topic: NodeTemplate,
    conversation: NodeTemplate,

    events: EventsTemplate,
    event: EventTemplate,
    tour: TourTemplate,
    retreat: RetreatTemplate,

    book: BookTemplate,
};


function AdminNodeInfo(props) {
    const {content} = props;

    const {userState} = useGlobalCustomContext();

    if (!isAdmin(userState?.user)) {
        return <></>
    }

    return <div className='AdminNodeInfo'>
        <div className='labelWrap'>
            {content._did && <>
                <span className="label">DID:</span>
                <span className='labelValue'>
                    {content._did}
                </span>
            </>}
            {content.originalDid && <>
                <span className="label">Alias:</span>
                <span className='labelValue'>
                    <a href={`?goToDid=${content.city}:${content.did}`} target='_blank'>{content.did}</a>
                </span>
            </>}
        </div>
    </div>
}

export default function Content() {
    // TODO: do not use useLoaderData one more time ! no reload page
    const loaderPageConfig = useLoaderData();

    const navigation = useNavigation();

    const {nodeState, nodeDispatch} = useGlobalCustomContext();

    const {configs} = useConfigStore();

    const [pageConfig, setPageConfig] = useState(loaderPageConfig);
    const [Node, setNode] = useState(null);

    const location = useLocation();
    const lastHash = useRef('');

    const city = getClientCity(window);

    const {setContent} = useContentStore();

    useEffect(() => {
        if (location.hash) {
            // safe hash for further use after navigation
            lastHash.current = location.hash.slice(1);
          }
    
          if (lastHash.current && document.getElementById(lastHash.current)) {
            setTimeout(() => {
              document
                .getElementById(lastHash.current)
                ?.scrollIntoView({ behavior: 'smooth', block: 'start' });

              lastHash.current = '';
            }, 100);
          }
    })

    // loading value for skeletons
    useEffect(() => {
        setPageConfig(loaderPageConfig);

        if (navigation.state === 'loading') {
            // this code added for mobile
            // on dom changes, search field move down without this code
            const ContentDOM = window.document.getElementById('Content');
            ContentDOM.style.minHeight = ''
        }
    }, [navigation])

    useEffect(() => {
        updateDocumentTitle();
        updateBodyStyles();

        setContent(pageConfig);

        let template = pageConfig.page.config.template || configs.content.nodes[pageConfig.page.content._type].template

        if (pageConfig.page.content._type === 'shop-product') {
            const {shop} = getShopDetails(pageConfig.page.content, pageConfig.breadcrumbs);
            if (!shop) return;

            template = shop.partner.config.productTemplate || template
        } else if (pageConfig.page.content._type === 'shop-section') {
            const {shop} = getShopDetails(pageConfig.page.content, pageConfig.breadcrumbs);
            if (!shop) return;

            template = shop.partner.config.productSectionTemplate || template        
        }

        if (typeof Templates[template] !== 'undefined') {
            setNode(() => React.createElement(
                Templates[template],
                {
                    children: {...pageConfig.children},
                    content: {...pageConfig.page.content, ...pageConfig?.page?.config?.values?.[pageConfig.page.content._type]},
                    config: {...pageConfig?.page?.config},
                    form: {...pageConfig.form},
                    breadcrumbs: {...pageConfig.breadcrumbs},
                    permits: {...pageConfig.permits},
                    key: pageConfig.page.content._did,
                }
            ));
        } else {
            // component doesn't exist yet
            setNode(React.createElement(
                () => <div>{template} template has not been created yet</div>
            ));
        }
    }, [pageConfig])

    useEffect(() => {
        if (nodeState.updatePageContentStart) {
            nodeDispatch({type: NODE_ACTIONS.UPDATE_PAGE_CONTENT_END});

            const notAuthorizedUserUpdates = {...nodeState.updatePageContentStartNotAuthorized}

            if (!isEmptyObj(notAuthorizedUserUpdates)) {
                pageConfig.children.list[notAuthorizedUserUpdates._uri] = notAuthorizedUserUpdates.page
                pageConfig.children.order.push(notAuthorizedUserUpdates._uri)
                setPageConfig({...pageConfig});
            }
            else {
                const path = {
                    city: city || DEFAULT_CITY,
                    uri: normalizeRoute(location.pathname)
                };

                getNodeContent(path, location.search)
                    .promise
                    .then(result => {
                        const data = result

                        if (!isEmptyObj(notAuthorizedUserUpdates)) {
                            data.children.list[notAuthorizedUserUpdates._uri] = notAuthorizedUserUpdates.page
                            data.children.order.push(notAuthorizedUserUpdates._uri)
                        }

                        setPageConfig(data)
                    })
            }
        }
    }, [nodeState])

    // updage titles
    const updateDocumentTitle = () => {
        let title = ''

        const cityConfig = getCityConfig(configs, city);
        if (cityConfig) {
            title = cityConfig.city + ' | '
        }

        setPageTitle(pageConfig.page.content.title);

        const classes = []

        classes.push(`node_${pageConfig.page.config.template || configs.content.nodes[pageConfig.page.content._type].template}`)

        if (pageConfig.page.content._uri && pageConfig.page.content._uri.length > 1)
            classes.push(`uri${pageConfig.page.content._uri.replaceAll('\\', '_')}`);

        setBodyClass(classes)
    }

    const updateBodyStyles = () => {
        // if (isMobile) document.body.classList.add('mobile');
        const url = new URL(window.location.href);
        const viewList = url.searchParams.get('view') === 'list';
        if (viewList) document.body.classList.add('view_list');
    }

    return <div id='Content' className='Content'>
        {!pageConfig.page.content._published && <div className='globalInformer error'>
            <ErrorOutlineOutlinedIcon /> <span>Сторінка знаходиться на премодерації. Її бачите лише автор та модератори</span>
        </div>}

        {Node}

        <span id='page_did'>{pageConfig.page.content._did}</span>

        <AdminNodeInfo content={pageConfig.page.content} />

        <NodeActions
            config={pageConfig}
            content={pageConfig.page.content}
            published={pageConfig.page.content._published}
        />
    </div>
}
