import Icon from '../../../../Components/Icon';
import DefaultImage from '../DefaultImage';

import './index.scss';

export default function TopicAvatar(props) {
    const {icon} = props;

    if (!icon) return <DefaultImage image="config/images/default/thumbnail_dark.png" className="TopicAvatar" />;

    if (icon.includes('mui:')) {
        return <span className="TopicAvatar">
            <Icon mui={icon.substring(4)} />
        </span>
    }
    else if (icon.includes('img:')) {
        return <span className="TopicAvatar">
            <img src={icon.substring(4)} />
        </span>
    }
    else {
        return <span className="TopicAvatar">
            {icon}
        </span>
    }
}
