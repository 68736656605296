exports.ROLES = {
    ADMIN: 'admin',
    MODERATOR: 'moderator',
    LIBRARY: 'library',
    EXCHANGE: 'exchange',
    OWNER: 'owner',
    IDENTIFIED: 'identified',
    ANONYMOUS: 'anonymous',
    TELEGRAM: 'telegram',
};
