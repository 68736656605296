import {useState} from 'react';
import {useNavigate} from "react-router-dom";

// import Badge from '@mui/material/Badge';

// import MenuItem from '@mui/material/MenuItem';
// import MenuList from '@mui/material/MenuList';

import {sendPasswordResetEmail} from "firebase/auth";

import {LOG_TYPES, HISTORY_USER} from "ultra/const/log";
import {DEFAULT_CITY} from 'ultra/const/general';
import {isLibrary, hasFullAccess, isAdmin} from 'ultra/helpers/auth';
import {restorePath} from 'ultra/helpers/route'
import {getClientCity, getCityConfig} from 'ultra/configs/general';
import {getClientUrl} from 'ultra/configs/general';

import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import ListIcon from '@mui/icons-material/List';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import SettingsIcon from '@mui/icons-material/Settings';
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';
import PasswordIcon from '@mui/icons-material/Password';
import HistoryIcon from '@mui/icons-material/History';
// import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined';
// import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
import FindReplaceIcon from '@mui/icons-material/FindReplace';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import CollectionsBookmarkOutlinedIcon from '@mui/icons-material/CollectionsBookmarkOutlined';
import HandshakeOutlinedIcon from '@mui/icons-material/HandshakeOutlined';
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';

import {useFirebaseStore} from '../../../../../Stores/firebase';
import {useConfigStore} from '../../../../../Stores/config';
import {useToasterStore} from '../../../../../Stores/toster';
import {useBackdropPreloaderStore} from '../../../../../Stores/backdropPreloader';
import {clearAllCache} from '../../../../Profile/Helpers/cache';
import {searchReindex} from '../../../../../Helpers/search';
import {useGlobalCustomContext} from '../../../../../Helpers/context';
import {userHasNodesForCity, userHasBooks, userHasNetworking} from '../../../../../Helpers/user';

import {saveUserHistoryAndNotify} from '../../../../Profile/Helpers/user';

import {USER_ACTIONS} from '../../../../Profile/Reducers/user';
import {NODE_ACTIONS} from '../../../../Content/Reducers/node';


export default function UserLinks(props) {
  const {closeMenu, onExit} = props;

  // const navigate = useNavigate();

  const {configs} = useConfigStore();
  const {auth} = useFirebaseStore();
  const {userState, nodeDispatch, userDispatch} = useGlobalCustomContext();

  const [spacesState, setSpacesState] = useState({});

  const [spaces, setSpaces] = useState([]);
  const [fullAccess, setFullAccess] = useState();
  // const [networkingLink, setNetworkingLink] = useState();
  // const [hasNotificaionsLink, setHasNotificaionsLink] = useState();

  const {showSuccess, showError} = useToasterStore();
  const {showBackdropPreloader, hideBackdropPreloader} = useBackdropPreloaderStore()

  useState(() => {
    if (userState?.user) {
      const fullAccess = hasFullAccess(userState?.user);

      setFullAccess(fullAccess)

      const spaces = []
      if (fullAccess) {
        if (configs?.content?.cities) {
          Object.keys(configs.content.cities).map(city => {
            if (!configs.content.cities[city].inProgress) spaces.push(city);
          });
        }
      }
      else {
        // const city = getClientCity(window) || DEFAULT_CITY;

        Object.keys(userState.user.library).map(city => {
          if (!spaces.includes(city)) spaces.push(city)
        })
        Object.keys(userState.user.nodes.all).map(city => {
          if (!spaces.includes(city)) spaces.push(city)
        })
        Object.keys(userState.user.nodes.singleton).map(city => {
          if (!spaces.includes(city)) spaces.push(city)
        })
      }
      setSpaces(spaces);
    }
  }, [userState?.user])

  const usersList = () => {
    closeMenu()
    window.location.assign(getClientUrl(DEFAULT_CITY, `/profile/users`))
  }

  const networkingList = (city) => {
    closeMenu()
      if (fullAccess) {
        window.location.assign(getClientUrl(city, `/profile/networking`))
      } else {
        const networking = userState?.user?.nodes?.singleton?.[city]?.['networking'];
        if (networking) {
          window.location.assign(getClientUrl(city, networking._uri))
        }
      }
  }

  const contentList = (city) => {
    closeMenu()
    window.location.assign(getClientUrl(city, `/profile/content`))
  }

  const libraryList = (city) => {
    closeMenu()
    window.location.assign(getClientUrl(city, `/profile/library`))
  }

  const partnersPage = () => {
    closeMenu()
    window.location.assign(getClientUrl(DEFAULT_CITY, `/profile/partnership`))
  }

  const onProfileEdit = () => {
    closeMenu();
    userDispatch({type: USER_ACTIONS.PROFILE_EDIT})
  }

  const onHistoryPage = () => {
    closeMenu();
    window.location.assign(getClientUrl(DEFAULT_CITY, `/profile/history`))
  }

  // TODO: make one function / has duplicate
  const onPasseEdit = () => {
    closeMenu();

    if (userState?.user.emailNotVerified) {
        showError({
            snackbarMessage: 'Для зміни паролю, необхідно підтвердити ваш email',
        })
        return;
    }

    showBackdropPreloader();

    // TODO: make similar to email validation
        // save flag
        // and move logs and history to backend

    sendPasswordResetEmail(auth, userState?.user?.email)
        .then(async () => {
            showSuccess({
                snackbarMessage: 'На вашу електронну адресу було направлено посилання для зміни паролю',
            });

            await saveUserHistoryAndNotify(userState?.user?.email, LOG_TYPES.INFO, HISTORY_USER.PASSWORD_UPDATE_REQUEST_EMAIL_SUCCESS);
        })
        .catch(async err => {
            console.log(err)
            if (err.code === 'auth/invalid-email') {
                showError({
                    snackbarMessage: `${userState?.user?.email} - не коректний email`
                });
            } else {
                showError({
                    snackbarMessage: 'Виникла помилка, спробуйте пізніше'
                });
            }

            await saveUserHistoryAndNotify(userState?.user?.email, LOG_TYPES.ERROR, HISTORY_USER.PASSWORD_UPDATE_REQUEST_EMAIL_ERROR, err);
        })
  }

  const onClearAllCache = (e) => {
    e.preventDefault();
    showBackdropPreloader();

    clearAllCache()
      .then(() => {
        setTimeout(() => {
          hideBackdropPreloader();
          nodeDispatch({type: NODE_ACTIONS.UPDATE_PAGE_CONTENT_START});
          // closeMenu();
        }, 500)
      })
      .catch((err) => {
          setTimeout(() => {
              showError({snackbarMessage: err.message})
              // closeMenu()
          }, 500)
      })
  }

  const onSearchReindexing = (e) => {
    e.preventDefault();
    showBackdropPreloader();

    searchReindex()
      .then((result) => {
        setTimeout(() => {
          showSuccess({
            snackbarMessage: `Додано: ${JSON.stringify(result)}`
          })
          hideBackdropPreloader();
          // closeMenu();
        }, 500)
      })
      .catch((err) => {
          setTimeout(() => {
              showError({snackbarMessage: err.message})
              // closeMenu()
          }, 500)
      })
  }

  function onCityClick(city) {
    const s = {};
    s.spaces = spacesState.spaces || {}
    s.spaces[city] = !Boolean(spacesState?.spaces?.[city]);
    setSpacesState(s);
  }

  function onSettingsClick() {
    const s = {};
    s.settings = !Boolean(spacesState.settings);
    setSpacesState(s);
  }

  function onAdminClick() {
    const s = {};
    s.admin = !Boolean(spacesState.admin);
    setSpacesState(s);
  }

  
  return <div className="UserLinksAdaptiveMenuWrapper">
        {spaces.sort().map(city => {
          if (
            userHasBooks(userState?.user?.library, city)
            || userHasNodesForCity(userState?.user?.nodes, city)
            || userHasNetworking(userState?.user?.nodes, city)
          ) {
              return <div key={city} className={spacesState?.spaces?.[city] ? 'open block space' : 'block space'} onClick={() => onCityClick(city)}>
                <div className='section'>
                  {!getCityConfig(configs, city).flag && <ListIcon className='icon' />}
                  {getCityConfig(configs, city).flag && <span className='flag icon'>{getCityConfig(configs, city).flag}</span>}
                  {getCityConfig(configs, city).city || "Головна"}
                  <KeyboardArrowUpIcon className='up' />
                  <KeyboardArrowDownIcon className='down' />
                </div>

                {spacesState?.spaces?.[city] && <div className='links'>
                  
                  {(fullAccess || userHasBooks(userState?.user?.library, city)) && city !== DEFAULT_CITY && <div className='link'>
                      <span className='linkWrap' onClick={() => libraryList(city)}>
                      <ListItemIcon>
                        <CollectionsBookmarkOutlinedIcon />
                      </ListItemIcon>
                      <ListItemText>
                        Бібліотека
                      </ListItemText>
                      </span>
                  </div>}

                  {(fullAccess || userHasNodesForCity(userState?.user?.nodes, city)) && <div className='link'>
                    <span className='linkWrap' onClick={() => contentList(city)}>
                      <ListItemIcon>
                        <AccountTreeOutlinedIcon />
                      </ListItemIcon>
                      <ListItemText>
                        Контент
                      </ListItemText>
                      </span>
                  </div>}

                  {(fullAccess || userHasNetworking(userState?.user?.nodes, city)) && <div className='link'>
                    <span className='linkWrap' onClick={() => networkingList(city)}>
                      <ListItemIcon>
                        <HandshakeOutlinedIcon />
                      </ListItemIcon>
                      <ListItemText>
                        Нетворкінг
                      </ListItemText>
                      </span>
                  </div>}

                </div>}
            </div>
          }
      })}

      {(fullAccess || userState?.user?.partnership) && <div className={spacesState?.admin ? 'open block' : 'block'} onClick={onAdminClick}>
        <div className='section'>
          <AdminPanelSettingsIcon className='icon'/>
          <span>Адмін панель</span>
          <KeyboardArrowUpIcon className='up' /> <KeyboardArrowDownIcon className='down' />
        </div>

        {spacesState?.admin && <div className='links'>

            {fullAccess && <div className='link'>
              <span className='linkWrap' onClick={onHistoryPage}>
                <ListItemIcon>
                  <HistoryIcon />
                </ListItemIcon>
                <ListItemText>
                  Історія
                </ListItemText>
                </span>
            </div>}

            {fullAccess && <div className='link'>
              <span className='linkWrap' onClick={usersList}>
              <ListItemIcon>
                <GroupOutlinedIcon />
              </ListItemIcon>
              <ListItemText>
                Користувачі
              </ListItemText>
              </span>
            </div>}

            {userState?.user?.partnership && <div className='link'>
              <span className='linkWrap' onClick={partnersPage}>
              <ListItemIcon>
                <ShoppingBagOutlinedIcon />
              </ListItemIcon>
              <ListItemText>
                Партнерство
              </ListItemText>
              </span>
            </div>}

            {fullAccess && <div className='link'>
              <span className='linkWrap' onClick={onClearAllCache}>
              <ListItemIcon>
                <CleaningServicesIcon />
              </ListItemIcon>
              <ListItemText>
                Очистити кеш
              </ListItemText>
              </span>
            </div>}

            {fullAccess && <div className='link'>
              <span className='linkWrap' onClick={onSearchReindexing}>
              <ListItemIcon>
                <FindReplaceIcon />
              </ListItemIcon>
              <ListItemText>
                Індексація
              </ListItemText>
              </span>
            </div>}
        </div>}
      </div>}

      <div className={spacesState?.settings ? 'open block' : 'block'} onClick={onSettingsClick}>
        <div className='section'>
          <SettingsIcon className='icon'/>
          <span>Налаштування</span>
          <KeyboardArrowUpIcon className='up' /> <KeyboardArrowDownIcon className='down' /></div>

        {spacesState?.settings && <div className='links'>
        <div className='link'>
            <span className='linkWrap' onClick={onProfileEdit}>
              <ListItemIcon>
                <DriveFileRenameOutlineOutlinedIcon />
              </ListItemIcon>
              <ListItemText>
                Редагувати
              </ListItemText>
              </span>
          </div>

          <div className='link' onClick={onPasseEdit}>
            <span className='linkWrap'>
              <ListItemIcon>
                <PasswordIcon />
              </ListItemIcon>
              <ListItemText>
                Пароль
              </ListItemText>
              </span>
          </div>
          </div>}
      </div>

      <div className='link ExitBtn' onClick={onExit}>
          <span className='linkWrap'>
          <ListItemIcon>
            <ExitToAppOutlinedIcon />
          </ListItemIcon>
          <ListItemText>
            Вийти
          </ListItemText>
          </span>
        </div>
    </div>
}
