import Button from '@mui/material/Button';

import {DEFAULT_CITY} from 'ultra/const/general';
import {getClientCity} from 'ultra/configs/general';

import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import ArrowCircleUpOutlinedIcon from '@mui/icons-material/ArrowCircleUpOutlined';

import {useGlobalCustomContext} from '../../../../../../../../../../Helpers/context';
import {getTelegramMode} from '../../../../../../../../../../Helpers/telegram';

import PriceWidget from '../../../../../../../../Widgets/Price';

import {NODE_ACTIONS} from '../../../../../../../../Reducers/node';

export default function DepositReturnStep(props) {
    const {submit, content, belongsToLibrary} = props;

    const {nodeDispatch} = useGlobalCustomContext();
    const city = getClientCity(window) || DEFAULT_CITY;

    return <>
        <div>
            <div className='section'>
                <div className="rule">
                    <span className='sectionIcon'>🏦 </span>
                    {belongsToLibrary && <span>
                        Після передачі книги вам буде повернуто <b><PriceWidget value={content.deposit} /></b>
                    </span>}
                    {!belongsToLibrary && <span>
                        Після оформлення заявки на повернення, власник має повернути вам <b><PriceWidget value={content.deposit} /></b>
                    </span>}
                </div>

                <div className="rule">
                    <span className='sectionIcon'>💥 </span>
                    <span>
                        Якщо книга була пошкоджена, депозит може бути повернутий частково. Попередній стан книги: <b>{content.condition.join(', ').toLowerCase()}</b>
                    </span>
                </div>
            </div>

            {!belongsToLibrary && <div className="info alert depositAlert">
                <ReportProblemOutlinedIcon />
                <div>
                    <p>Якщо власник книги не поверне кошти, він буде
                        заблокований в 
                        {getTelegramMode() && <> екосистемі Bali Ukraine</>}
                        {!getTelegramMode() && <> системі Prostir та екосистемі Bali Ukraine</>}

                        <span> і доданий </span>
                        <span className='pseudoLink' onClick={() => nodeDispatch({type: NODE_ACTIONS.POPUP_PAGE, data: {city, did: '1305a1ibHrnJJ3DqEXQZ' }})}>до списку боржників</span>
                    </p>
                </div>
            </div>}
        </div>

        <div className="submit">
            <Button startIcon={<ArrowCircleUpOutlinedIcon />} variant="contained" fullWidth onClick={submit}>Відправити запит</Button>
        </div>
    </>
}
