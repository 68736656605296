import Button from '@mui/material/Button';
import {DEFAULT_CITY} from 'ultra/const/general';
import {getClientCity} from 'ultra/configs/general';

import {useGlobalCustomContext} from '../../../../../../../../../../Helpers/context';
import {NODE_ACTIONS} from '../../../../../../../../Reducers/node';

import {getTelegramMode} from '../../../../../../../../../../Helpers/telegram';

export default function SanctionsStep(props) {
    const {content, submit} = props;

    const {nodeDispatch} = useGlobalCustomContext();
    const city = getClientCity(window) || DEFAULT_CITY;

    return <>
            <div className='section'>
                <div className="step">Якщо я пошкоджу книгу?</div>
                <div className="rule">
                    🚫&nbsp;
                    депозит буде повернено частково, відповідно до стану книги
                </div>
            </div>

            <div className='section'>
                <div className="step">Якщо я не поверну книгу?</div>
                <div className="rule">
                    🚫&nbsp;
                    {getTelegramMode() && <>доступ до екосистеми Bali Ukraine буде заблоковано</>}
                    {!getTelegramMode() && <>доступ до системи Prostir та екосистеми Bali Ukraine буде заблоковано</>}
                </div>
                <div className="rule">
                    🚫&nbsp;
                    <span>ви будете додані </span>
                    &nbsp;
                    <span className='pseudoLink' onClick={() => nodeDispatch({type: NODE_ACTIONS.POPUP_PAGE, data: {city, did: '1305a1ibHrnJJ3DqEXQZ' }})}>до списку боржників</span>
                </div>
            </div>

            <div className="section rulesLinkSection">
                📄&nbsp;&nbsp;
                <span className='pseudoLink' onClick={() => nodeDispatch({type: NODE_ACTIONS.POPUP_PAGE, data: {city, did: 'g0jb7AElHeLpnF7ezk11' }})}>Ознайомитись з повними правилами</span>
            </div>

            {content.deposit && <div className="submit">
                <Button variant="contained" fullWidth onClick={submit}>Приймаю правила</Button>
            </div>}
    </>
}
