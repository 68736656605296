import './index.scss';
import CurrencyFormated from '../../../../Components/CurrencyFormated'

export default function ShopProductPrice(props) {
    const {product, currency} = props;

    if (!product || !currency) return <></>

    if (product.discount) {
        return <span className="ShopProductPrice">
            <span className='oldPrice'>
                <CurrencyFormated value={product.price} currency={currency} />
            </span>
            <CurrencyFormated value={product.discount} currency={currency} />
        </span>
    } else {
        return <span className="ShopProductPrice">
            <CurrencyFormated value={product.price} currency={currency} />
        </span>
    }
}
