import {isMobile} from "react-device-detect";
import {useNavigate} from "react-router-dom";

import Badge from '@mui/material/Badge';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';

// import {DEFAULT_CITY} from 'ultra/const/general';
// import {getClientCity} from 'ultra/configs/general';
// import {getClientUrl} from 'ultra/configs/general';

import Avatar from '../../../Widgets/Avatar';
import {useGlobalCustomContext} from '../../../../../Helpers/context';

export default function UserLinksHeader(props) {
    const {closeMenu} = props;
  
    const {userState} = useGlobalCustomContext();
    const navigate = useNavigate();

    const notificationsList = () => {
      closeMenu();
      navigate('/profile/messages');
      // const city = getClientCity(window) || DEFAULT_CITY;
      // window.location.assign(getClientUrl(city, '/profile/messages'));
    }

    return <div className='UserCard WithActions'>
        <span className='UserLogo'>
          <Avatar image={userState?.user?.photoURL} roles={userState?.user?.roles} minimal={`${isMobile ? 'false' : 'true'}`}/>
        </span>
  
        <p className='CardTitle'>{userState?.user?.displayName}</p>
        <p className='CardEmail'>{userState?.user?.email}</p>
  
        <Badge
            className='income'
            color='error'
            badgeContent={userState?.user?.income}
            onClick={notificationsList}
          >
          <NotificationsActiveOutlinedIcon />
        </Badge>
  
        {userState?.user?._not_checked && <div className='informer globalInformer error'>
          <ErrorOutlineIcon />
          Ваш аккаунт проходить перевірку після реєстрації
        </div>}
  
        {userState?.user?.emailNotVerified && <div className='informer verifyEmailWrap'>
          <AlternateEmailIcon />
          Підтвердіть вашу електрону адресу, перейшовши за посиланням, яке ми відправили вам на email
        </div>}
      </div>
  }
