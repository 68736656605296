import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import {DEFAULT_CITY} from 'ultra/const/general';
import {getClientCity} from 'ultra/configs/general';

import MessageToOwner from '../../MessageToOwner';

import {useGlobalCustomContext} from '../../../../../../../../../../Helpers/context';
import {NODE_ACTIONS} from '../../../../../../../../Reducers/node';

import {getTelegramMode} from '../../../../../../../../../../Helpers/telegram';

import PriceWidget from '../../../../../../../../Widgets/Price';

export default function FinalStep(props) {
    const {content, belongsToLibrary, handleGetBookClose} = props;

    const {nodeDispatch} = useGlobalCustomContext();
    const city = getClientCity(window) || DEFAULT_CITY;

    if (belongsToLibrary) {
        return <>
            <p className="titleEmojiText titleEmojiTitle">Книгу "{content.title}" заброньовано за вами</p>
            <p className="titleEmoji">🎉</p>
            <p className="titleEmojiText">Очікуйте на підтвердження від бібліотекаря {getTelegramMode() ? 'у вашому телеграм боті' : 'за вказаними вами контактними даними'} </p>
        </>
    }

    return <>
        <p className="titleEmojiText titleEmojiTitle">Книгу "{content.title}" заброньовано за вами</p>
        <p className="titleEmoji">🎉</p>
        {/* // TODO: remove after Prostir release */}
        {belongsToLibrary && <p className="titleEmojiText">Для 
            {content?.deposit?.price && <span>передачі завдатку <PriceWidget value={content.deposit} /> та </span>} 
            <span>отримання книги - зв'яжіться з власником</span>
        </p>}

        {/* // TODO: remove after Prostir release */}
        {belongsToLibrary && <div className="contact">
            <MessageToOwner belongsToLibrary={belongsToLibrary} book={content} onClick={handleGetBookClose}/>
        </div>}

        {!belongsToLibrary && content?.deposit?.price && <div className="info alert depositAlert">
            <ReportProblemOutlinedIcon />
            <div>
                <p>Щоб уникнути скаму - передавайте кошти під час отримання книги</p>
            </div>
        </div>}

        {!belongsToLibrary && content?.deposit?.price && <div className="info alert depositAlert">
            <ReportProblemOutlinedIcon />
            <div>
                <p>Якщо власник книги не поверне кошти, він буде
                    заблокований в 
                    {getTelegramMode() && <> екосистемі Bali Ukraine</>}
                    {!getTelegramMode() && <> системі Prostir та екосистемі Bali Ukraine</>}

                    <span> і доданий </span>
                    <span className='pseudoLink' onClick={() => nodeDispatch({type: NODE_ACTIONS.POPUP_PAGE, data: {city, did: '1305a1ibHrnJJ3DqEXQZ' }})}>до списку боржників</span>
                </p>
            </div>
        </div>}
    </>
}
